<template>
  <div class="products-comparison" v-if="pageData">
    <div class="container">
      <a
        class="products-comparison__back-button"
        v-if="productsToCompare.length"
        href="/"
        @click.prevent="$router.go(-1)"
      >
        <i class="icon-left-arrow"></i>
        Back to Product List
      </a>
      <h1 class="text-h2 products-comparison__title">Product Comparison</h1>
      <div class="products-comparison__products">
        <div
          ref="productsTable"
          class="table table--products"
          :class="[pageData.products.length > 4 ? 'table--scrollable' : `table--${pageData.products.length}`]"
        >
          <div
            class="table__col table__col--name"
            :style="{ width: `${firstColumnWidth}px` }"
          >
            <FormToggle
              v-model="showDifferencesOnly.value"
              :field="showDifferencesOnly"
            />
          </div>
          <div
            ref="productsScroll"
            class="table__scrollable-block"
            :style="pageData.products.length > 4 ? {flexBasis: `${productColumnWidth}px`} : null"
          >
            <div
              v-for="product in pageData.products"
              :key="product.sku"
              class="table__col table__col--content"
            >
              <div
                class="products-comparison__products-item"
                :class="{'products-comparison__products-item--ie': isIE}"
              >
                <CarouselProduct
                  :productData="product.data"
                  :isPriceVisible="false"
                  :isFavoriteVisible="true"
                  :analyticsData="{referencePage: 'Product Compare'}"
                />
                <ProductPrice
                  :productData="product.data"
                  :isPurchasable="product.data.isPurchasable && checkIfProductPurchasableForCurrentUser(product.data)"
                  :showPriceLabels="true"
                />
                <div
                  class="products-comparison__products-actions"
                  :class="{
                    'products-comparison__products-actions--stack': pageData.products.length > 2 || !isDesktop
                  }"
                  v-if="product.data.hasPrice && product.data.isPurchasable && checkIfProductPurchasableForCurrentUser(product.data)"
                >
                  <ProductQuantityInput
                    :minOrderQty="product.data.minOrderQty"
                    v-model="product.data.addToCartQty"
                  />
                  <button
                    type="button"
                    class="button button--primary button--cart"
                    :disabled="!product.data.addToCartQty"
                    @click="onAddToCartClick(product.data, product.data.addToCartQty)"
                  >
                    <i class="icon-shopping-cart"></i>
                    Add to Cart
                  </button>
                </div>
                <div class="products-comparison__products-remove-wrap">
                  <button
                    v-if="pageData.products.length > 2"
                    @click="deleteProduct(product.data.sku)"
                    class="button button--underlined"
                  >Remove</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="products-comparison__specifications">
        <h2 class="text-h3">Specifications</h2>
        <div
          class="products-comparison__specifications-item"
          v-for="specification in pageData.specifications"
          v-show="specification.hasDifferences || !showDifferencesOnly.value"
          :key="specification.name"
        >
          <h3 class="text-h4 products-comparison__specifications-title" v-html="specification.name"></h3>
          <div class="products-comparison__specifications-table-wrap">
            <div
              v-if="specification.list"
              class="table table--specification"
              :class="[
                pageData.products.length > 4 ? 'table--scrollable' : `table--${pageData.products.length}`,
                isSpesificationsDragging ? 'dragging' : null
              ]"
            >
              <div
                v-for="specificationValue in specification.list"
                v-show="specificationValue.hasDifferences || !showDifferencesOnly.value"
                :key="specificationValue.name"
                class="table__row"
                :class="{'highlight': checkRowValuesAreDifferent(specificationValue.list)}"
              >
                <div
                  class="table__col table__col--name"
                  :style="{ width: `${firstColumnWidth}px` }"
                >{{ specificationValue.name }}</div>
                <div
                  ref="specificationScroll"
                  class="table__scrollable-block"
                  :style="pageData.products.length > 4 ? {flexBasis: `${productColumnWidth}px`} : null"
                  @[specificationsScrollEvents.down]="handleSpecificationScrollMousedown"
                >
                  <div
                    v-for="(value, idx) in specificationValue.list"
                    :key="idx"
                    v-html="value || '-'"
                    class="table__col table__col--content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalCart
      v-if="showModalCart"
      @close="closeCartModal"
      @addProduct="onAddToCartFromModalCart"
      :isLoading="isModalCartLoading"
      :productEntry="productEntryData"
      :showModalCart="showModalCart"
    />

    <ModalInventory
      :inventoryData="inventoryData"
      :showModal="showModalInventory"
      @close="closeInventoryModal"
    />

    <loader v-if="isLoading" />
  </div>
  <loader v-else />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import _ from 'lodash'

import settings from '@/settings'
import LoaderMixin from '@/mixins/LoaderMixin'
import AddToCartMixin from '@/mixins/AddToCartMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import UtilityMixin from '@/mixins/UtilityMixin'
import CarouselProduct from '@/components/CarouselProduct'
import ProductQuantityInput from '@/components/ProductQuantityInput'
import ModalCart from '@/components/modal/ModalCart'
import ModalInventory from '@/components/modal/ModalInventory'
import FormToggle from '@/components/FormToggle'
import ProductPrice from '@/components/ProductPrice'

export default {
  name: 'ProductsComparison',
  components: {
    CarouselProduct,
    ProductQuantityInput,
    ModalInventory,
    ModalCart,
    FormToggle,
    ProductPrice
  },
  mixins: [LoaderMixin, AddToCartMixin, ScreenWidthMixin, UtilityMixin],
  computed: {
    ...mapState('productsComparison', ['comparisonData', 'productsToCompare']),
    ...mapState('user', ['favoriteProductsCodes']),
    ...mapGetters('user', ['isPublic', 'isEmployee', 'isGuest']),
    firstColumnWidth () {
      return this.isDesktop ? 240 : 140
    }
  },
  methods: {
    ...mapActions('productsComparison', ['setComparisonData', 'constructSpecifications', 'clearData']),
    ...mapActions('user', ['setFavoriteProductsCodes']),
    ...mapActions('product', ['getProduct']),
    ...mapMutations('productsComparison', {
      setProductsToCompare: 'SET_PRODUCTS_TO_COMPARE'
    }),
    checkRowValuesAreDifferent (valuesObject) {
      const values = Object.values(valuesObject)
      return values?.some(el => el !== values[0])
    },
    onAddToCartClick (productData, addToCartQty) {
      this.withLoader(() => this.addProductToCart({
        productData,
        addToCartQty,
        position: 0,
        pageName: 'Product Compare',
        searchQuery: '',
        indexName: this.productsToCompare.find(el => el.productData?.sku === productData.sku)?.indexName,
        queryID: this.productsToCompare.find(el => el.productData?.sku === productData.sku)?.queryID
      }))
    },
    onAddToCartFromModalCart ({ productData, addToCartQty }) {
      this.isModalCartLoading = true
      return this.addProductToCart({
        productData,
        addToCartQty,
        position: 0,
        pageName: 'Related Products Add to Cart',
        searchQuery: ''
      }).finally(() => {
        this.isModalCartLoading = false
      })
    },
    checkIfProductPurchasableForCurrentUser (productData) {
      if (this.isPublic && productData.isPublicStore) return true
      if (this.isEmployee && productData.isEmployeeStore) return true
      if (this.isAffiliate && productData.isAffiliateStore) return true
      return false
    },
    async deleteProduct (productCode) {
      const productsSku = this.$route.query[settings.app.queryTokens.productsToCompare]?.split(',')
      const remainingProductsCodes = productsSku.filter(el => el !== productCode)
      this.$router.replace({
        query: {
          [settings.app.queryTokens.productsToCompare]: remainingProductsCodes.join(',')
        }
      })

      this.setProductsToCompare(this.productsToCompare.filter(el => el.productData.sku !== productCode))

      this.pageData.products = this.pageData.products.filter(el => el.data.sku !== productCode)
      this.pageData.specifications = await this.constructSpecifications(this.pageData.products.map(el => el.data))
    },
    hanleProductsScroll: _.throttle(function (e) {
      const currentScrollPosition = e.target.scrollLeft
      this.$refs.specificationScroll.forEach(ref => {
        ref.scrollLeft = currentScrollPosition
      })
    }, 15),
    handlePageResize: _.throttle(function () {
      const visibleItemsAmount = this.isMobile ? 3 : 4
      const sizeMakeRightBorderVisible = this.isDesktop ? 0.5 : 0
      const calculatedWidth = ((this.$refs.productsTable?.clientWidth - this.firstColumnWidth) / visibleItemsAmount) - sizeMakeRightBorderVisible
      this.productColumnWidth = calculatedWidth || this.firstColumnWidth
    }, 15),
    handleSpecificationScrollMousedown (e) {
      this.isSpesificationsDragging = true
      this.mousePosition = {
        left: this.$refs.specificationScroll[0].scrollLeft,
        x: this.isTouchScreen ? e.targetTouches[0].clientX : e.clientX
      }
      document.addEventListener(this.specificationsScrollEvents.move, this.handleMouseMove)
      document.addEventListener(this.specificationsScrollEvents.up, this.handleSpecificationScrollMouseup)
    },
    handleSpecificationScrollMouseup () {
      this.isSpesificationsDragging = false
      document.removeEventListener(this.specificationsScrollEvents.move, this.handleMouseMove)
      document.removeEventListener(this.specificationsScrollEvents.up, this.handleSpecificationScrollMouseup)
    },
    handleMouseMove: _.throttle(function (e) {
      const clientX = this.isTouchScreen ? e.targetTouches[0].clientX : e.clientX
      const dx = clientX - this.mousePosition.x
      const scrollLeft = this.mousePosition.left - dx
      this.$refs.specificationScroll.forEach(ref => {
        ref.scrollLeft = scrollLeft
      })
      this.$refs.productsScroll.scrollLeft = scrollLeft
    }, 15)
  },
  async created () {
    const productsSku = this.$route.query[settings.app.queryTokens.productsToCompare]?.split(',')
    if (!productsSku || productsSku?.length < 2) {
      this.$router.push('/')
      return
    }

    await this.setComparisonData(productsSku)
    this.pageData = this.comparisonData

    this.specificationsScrollEvents = {
      down: this.isTouchScreen ? 'touchstart' : 'mousedown',
      up: this.isTouchScreen ? 'touchend' : 'mouseup',
      move: this.isTouchScreen ? 'touchmove' : 'mousemove'
    }

    this.$nextTick(() => {
      if (this.$refs.productsScroll) this.$refs.productsScroll.addEventListener('scroll', this.hanleProductsScroll)
      if (this.$refs.productsTable) {
        this.handlePageResize()
        window.addEventListener('resize', this.handlePageResize)
      }
    })

    if (!this.isGuest && !this.favoriteProductsCodes) this.setFavoriteProductsCodes()
  },
  beforeDestroy () {
    this.clearData()
    if (this.$refs.productsScroll) this.$refs.productsScroll.removeEventListener('scroll', this.hanleProductsScroll)
    if (this.$refs.productsTable) window.removeEventListener('resize', this.handlePageResize)
  },
  data () {
    return {
      pageData: null,
      showDifferencesOnly: {
        type: 'checkbox',
        label: 'Show differences only',
        value: false
      },
      isModalCartLoading: false,
      productColumnWidth: 250,
      mousePosition: { left: 0, x: 0 },
      isSpesificationsDragging: false,
      specificationsScrollEvents: {
        down: 'mousedown',
        up: 'mouseup',
        move: 'mousemove'
      }
    }
  }
}
</script>
